import { graphql, useStaticQuery } from "gatsby"
import { SmallBannerDiv, CtaButton, CoverImage } from "../styles/styles"
import Img from "gatsby-image"
import React from "react"



const SmallBanner = (props) => {

  // const data = useStaticQuery(graphql`
  //   query contactBanner {
  //     image: file(relativePath:
  //       {
  //         eq: ${props.image}
  //       }
  //     )
  //     {  
  //       id
  //       childImageSharp {
  //         fixed {
  //           ...GatsbyImageSharpFixed
  //         }
  //         fluid {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  // console.log(data)

  return (
    <SmallBannerDiv>
      <CoverImage>
        {/* <Img fluid={data.image.childImageSharp.fluid} /> */}
        <img src={props.image} style={{width: '100%',height: '100%',objectFit: 'cover',objectPosition: props.position}} />
      </CoverImage>
      
      {props.children}
    </SmallBannerDiv>
  )
} 

export default SmallBanner
