import React from 'react'
import { Link } from "gatsby"
import { DummyButton } from '../styles/styles'

const Box = (props) => {
    return (
        <Link to={props.link}>
            <h4>{props.title}</h4>
            {props.children}
            <DummyButton style={{marginTop:'30px'}}>{props.buttonText}</DummyButton>
        </Link>
    )
}

export default Box